import React from 'react'
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'

export default function Contact() {
    return (
        <Layout>
            <div className="ourstory_sectiononewraper contact_sectiononewraper">
                <div className="row ourstory-sectionone-wraped contact_sectiononewraped container-max-width">
                    <div className="col-md-12 ourstory-sectionone-wraped-inner contact_sectiononewraped-inner">
                        <h1 className="subtitle">
                            <Trans>Get in Touch</Trans>
                        </h1>
                    </div>
                </div>
            </div>
            <div className="contact_sectiontwowraper">
                <div className="contact_sectiontwowraped">
                    <div className="contact-infowraper row">
                        <div className="col-md-4 info-sectionwraper">
                            <div className="subtitle-descrription-wraper">
                                <h2 className="subtitle">
                                    <Trans>Location</Trans>
                                </h2>
                                <div className="descptionwraper">
                                     <Trans>WeWork Prestige Central, 36 Infantry Rd, Tasker Town, Bengaluru, Karnataka 560001</Trans>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 info-sectionwraper">
                            <div className="subtitle-descrription-wraper">
                                <h2 className="subtitle">
                                    <Trans>General Inquiry</Trans>
                                </h2>
                                <div className="descptionwraper">
                                    <Trans>If you are interested in our English solution, get in touch:</Trans><Link className="links-wraper" aria-current="page" to="mailto:email@talkingyak.com">email@talkingyak.com</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 info-sectionwraper">
                            <div className="subtitle-descrription-wraper">
                                <h2 className="subtitle">
                                    <Trans>Business & Press Inquiry</Trans>
                                </h2>
                                <div className="descptionwraper">
                                    <Trans>If your organisation is interested in partnering with Talking Yak, get in touch:</Trans><Link className="links-wraper" aria-current="page" to="mailto:email@talkingyak.com">email@talkingyak.com</Link>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
{
	locales: allLocale {
	  edges {
		node {
		  language
		  ns
		  data
		}
	  }
	}
}
`